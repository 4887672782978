<template>
  <div id="app" :class="contextName">
    <app-header />
    <div class="content-all" :class="{'bg-wrapper': isPaywallView}">
      <router-view/>
    </div>
    <app-footer />
  </div>
</template>

<script>
import AppMixin from '@/base/mixins/app.mixin'
import AppHeader from '@/base/components/Header/AppHeader'
import AppFooter from '@/base/components/Footer/AppFooter'

export default {
  mixins: [AppMixin],
  components: {
    AppFooter,
    AppHeader
  }
}
</script>

<style src="./assets/scss/main.scss" lang="scss"></style>
